export const percentFat = [
    { name: "4-6%", id: 1 },
    { name: "<4%", id: "2" },
];

export const breedList = [
    { name: "Crossbred", id: 5 },
    { name: "Holstein", id: 8 },
    { name: "Jersey", id: 9 },
  ];

export const grams_per_list = [{ id: 1, name: 1 }];

export const milk_sold_to_list = [
    { id: 1, name: "Addison" },
    { id: 2, name: "Advanced Milk Commodities" },
    { id: 3, name: "Agri Mark" },
    { id: 4, name: "Agropur" },
    { id: 5, name: "Baker Cheese" },
    { id: 6, name: "Burnett Dairy Cooperative" },
    { id: 7, name: "california dairy inc" },
    { id: 8, name: "Cobblestone" },
    { id: 9, name: "Dannon" },
    { id: 10, name: "Darigold" },
    { id: 11, name: "Dairy Farmers of America" },
    { id: 12, name: "First Dist. Assoc." },
    { id: 13, name: "First District Assn." },
    { id: 14, name: "Foremost Farms" },
    { id: 15, name: "Grande Cheese" },
    { id: 16, name: "Grassland Dairy Products Inc." },
    { id: 17, name: "Hilmar Cheese" },
    { id: 18, name: "Holland Patent Coop" },
    { id: 19, name: "Independent" },
    { id: 20, name: "Land O' Lakes" },
    { id: 21, name: "Maryland Virginia" },
    { id: 22, name: "Middlebury" },
    { id: 23, name: "Michigan Milk Producers Association" },
    { id: 34, name: "Milk Board"},
    { id: 24, name: "Mullins Cheese" },
    { id: 25, name: "NFO" },
    { id: 26, name: "Point Reyes Farmstead Cheese" },
    { id: 27, name: "Rothenbuhler Cheese" },
    { id: 28, name: "Rutters Independen" },
    { id: 29, name: "Saputo" },
    { id: 30, name: "Sartori Cheese COo." },
    { id: 35, name: "The Alberta Milk Board"},
    { id: 31, name: "Upstate" },
    { id: 32, name: "Valley Milk" },
    { id: 33, name: "WestCo" },
  ]

  

  export const get_milk_sold_to_list = (country = null) => {
    // Check if param is a number (id) or a string (name)
    if (!country) {
      country = localStorage.getItem('selectedProjectCountry');
    }

    if (country === 'Canada') {
      return milk_sold_to_list.filter(milk => milk.name === "Milk Board");
    }
    return milk_sold_to_list;
  };


export const dmi_validation = [
    {
      breed: "Crossbred",
      min: 43,
      max: 50,
    },
    {
      breed: "Holstein",
      min: 53,
      max: 64,
    },
    {
      breed: "Jersey",
      min: 42,
      max: 46,
    },
  ];

export const checkDMIValidation = (breed, dmi, country) => {
		const validation = dmi_validation.find((item) => item.breed === breed);
		if (!validation) return false;

		let { min, max } = validation;
    if (country === 'Canada') {
      min = lb_to_kg(min);
      max = lb_to_kg(max);
    }

		if (dmi >= min && dmi <= max) {
			return { error: false };
		} else {
			return {
				error: true,
				message: `For ${breed} the typical dmi range is ${min.toFixed(2)} - ${max.toFixed(2)}`,
			};
		}
	};

export const lb_to_kg = (lb) => {
    return lb / 2.20462;
}

export const kg_to_lb = (kg) => {
    return kg * 2.20462;
}

export const agolin_product_used_list = [
  { id: 1, name: "Agolin™ Ruminant" },
  { id: 2, name: "Agolin™ 101" },
  { id: 3, name: "Agolin™ Ruminant Liquid" },
  { id: 4, name: "Agolin™ Naturu" },
]

export const get_agolin_product_used_list = (country = null) => {
  if (!country) {
    country = localStorage.getItem('selectedProjectCountry');
  }

  if (country === 'Canada') {
    return agolin_product_used_list.filter(milk => milk.name === "Agolin™ 101");
  }
  return agolin_product_used_list;
};


export const organic_or_conventional_list = [
  { id: 1, name: "Organic" },
  { id: 2, name: "Conventional" },
]