import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import './Modals.css';
import { Button } from '../Button/Button';

const ApproveModal = ({ isApproveModalOpen, setIsApproveModalOpen, submitFunction, runReceiptInfoValidation, lang}) => {
  const [checkboxes, setCheckboxes] = useState([false, false, false, false]);
  const [plausilibityCheckError, setPlausibilityCheckError] = useState(false);

  useEffect(() => {
    console.log('<MODAL CHNGE', isApproveModalOpen);
    if (!isApproveModalOpen) {
      setCheckboxes([false, false, false, false]);
    } else {
      console.log('RUNNING PLAUSI CHECK');
      setPlausibilityCheckError(runReceiptInfoValidation());
    }
  }, [isApproveModalOpen]);

  const handleCheckboxChange = (index) => {
    setCheckboxes((prev) => {
      const newCheckboxes = [...prev];
      newCheckboxes[index] = !newCheckboxes[index];
      return newCheckboxes;
    });
  };

  const allChecked = checkboxes.every(Boolean) && (plausilibityCheckError === false);

  return (
    <Modal
      show={isApproveModalOpen}
      onHide={() => setIsApproveModalOpen(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="reject-modal"
    >
      <Modal.Body>
        <div className="custom-modal-content" style={{ padding: "12px 20px" }}>
          <div className="reject-title-cls">{lang === 'en' ? 'Approve the Farm Form' : 'Approuver le formulaire de ferme'}</div>
          <div>{lang==='en' ? 'Clicking approve below will officially submit and lock the Farm Data. Please review the data and files before approving.' : "En cliquant sur approuver ci-dessous, vous soumettrez et verrouillerez officiellement les données agricoles. Veuillez examiner les données et les fichiers avant d'approuver."}</div>
          <div className="reject-body-content">

            <div className="form-field" style={{ width: '100%' }}>
              {plausilibityCheckError && 
                <div className="error-msg">{lang === 'en' ? "Data Validation Check failed: Please review the receipt and farm animal data" : "Échec de la vérification de validation des données : veuillez examiner le reçu et les données sur les animaux de ferme."}</div>
              }
              <br />
              {[
                lang === 'en' ? "I have ensured the farm has uploaded all primary data as required": "Je me suis assuré que la ferme a téléchargé toutes les données primaires comme requis",
                lang === 'en' ? "I have reviewed that Uploaded primary data matches 'Animal Data' entered in the form": "J'ai vérifié que les données primaires téléchargées correspondent aux données sur les animaux saisies dans le formulaire",
                lang === 'en' ? "I have correctly entered the Agolin™ purchase data based on the receipts after reviewing the uploaded Agolin™ purchase receipts": "J'ai correctement saisi les données d'achat Agolin™ sur la base des reçus après avoir examiné les reçus d'achat Agolin™ téléchargés",
                lang === 'en' ? "I have reviewed the data with the Farmer":"J'ai examiné les données avec l'agriculteur"
              ].map((text, index) => (
                <div key={index}>
                  <input
                    type="checkbox"
                    checked={checkboxes[index]}
                    onChange={() => handleCheckboxChange(index)}
                    className="handle-check-input"
                  />&ensp;{text}
                </div>
              ))}
            </div>
            <div className="buttons-reject">
              <Button
                text={lang==='en' ? "Approve": "Approuver"}
                width="160px"
                handleClick={() => {
                  submitFunction('approve');
                  setIsApproveModalOpen(false);
                }}
                disabled={!allChecked}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ApproveModal;
