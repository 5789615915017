import React, { useState, useRef, useEffect } from 'react';
import './FarmForm.css';
import { Button } from '../../Components/Button/Button';
import 'react-phone-number-input/style.css';
import FormComponent from '../../Components/CommonFormField/FormComponent';
import DeleteIcon from '../../images/Analytics/Group 34070.svg';
import LogoNew from '../../images/Auth/SVG 1.svg';
import AnimalFormComponent from '../../Components/CommonFormField/AnimalFormComponent';
import addIcon from '../../images/Facilitator/AddComponent.svg';
import {
	CheckToken,
	approveStatus,
	updateFarmForm,
	deleteDocument,
	getDataFromUniqueLink,
	getDocumentList,
	uploadDocument,
	getPresignedURL,
} from '../../api/Farm/endpoints';
import Loader from '../../Components/Loader/loader';
import Cookies from 'js-cookie';
import Verifyfacilitator from './Verifyfacilitator';
import { useParams, useNavigate } from 'react-router-dom';
import { toastFailure, toastSuccess } from '../../Components/Toaster/Toast';
import RejectModal from '../../Components/Modals/FarmForm_RejectModal';
import ApproveModal from '../../Components/Modals/FarmForm_ApproveModal';

import {
	percentFat,
	breedList,
	grams_per_list,
	get_milk_sold_to_list,
	checkDMIValidation,
	get_agolin_product_used_list,
	organic_or_conventional_list,
	kg_to_lb,
} from './constants';
import { useSelector, useDispatch } from 'react-redux';
import { getUserPermissions } from '../../api/auth/endpoints';
import { reduxUserPermission } from '../../api/auth/authSlice';

const FarmForm = () => {
	const { id } = useParams(); // Unique Key
	const [lang, setLang] = useState('en');

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { userPermission } = useSelector((state) => state.auth);

	const [isLoading, setIsLoading] = useState(true);
	const [isSaveLoading, setIsSaveLoading] = useState(false);

	const [isAuthenticated, setIsAuthenticated] = useState(false);

	const [firstTimeLoad, setFirstTimeLoad] = useState(true);

	const [farmFormStatus, setFarmFormStatus] = useState();
	const [isFacilitator, setIsFacilitator] = useState(true);

	const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
	const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
	const [uploadedFiles, setUploadedFiles] = useState([]);

	// const [checkboxForSharingData, setCheckboxForSharingData] = useState(false);

	// const [forcedStartDate, setForcedStartDate] = useState(null);
	// FORM LOGISTICS
	// Step 1: Get User Permissions on Page Load
	const getUserData = async () => {
		const responseUserPermission = await getUserPermissions();
		if (responseUserPermission.data.status === 200) {
			dispatch(reduxUserPermission(responseUserPermission.data.result));
			if (responseUserPermission.data.result.role === 'Farmer') {
				setIsFacilitator(false);
			}
		}
	};
	
	const checkToken = async (token) => {
		try {
			const responseToken = await CheckToken(token);
			if (responseToken.status === 200) {
				setIsAuthenticated(true);
			} else {
				toastFailure(responseToken.data);
			}
		} catch (error) {
			toastFailure('An error occurred while checking the token.');
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		const getToken = Cookies.get('auth_token');
		if (getToken) {
			checkToken(getToken);
		} else {
			setIsLoading(false);
		}

		if (!userPermission) {
			getUserData();
		}
	}, []);

	const [projectInfo, setProjectInfo] = useState({
		form_active_save: false, // Form is active for saving
		form_active_submit: false, // Form is active for submitting
		farm_form_submission_start: null, // Start date for submitting the form
		farm_form_submission_end: null, // End date for submitting the form
		previous_project_farm_exists: false, // If the farmer has a previous project
		project_start_date: null,
		project_end_date: null,
	});

  	// SECTION 1: farmInfo
	const initialFarmInfo = [
		{ label: 'Name of the Person with Signing Authority [e.g.John Doe]', type: 'text', id: 'owner_name', label_fr: 'Nom de la personne ayant l\'autorité de signature [ex. Jean Dupont]' },
		{ label: 'Farmer Phone Number', type: 'phone', id: 'owner_phone', state: '', label_fr: 'Numéro de téléphone du fermier' },
		{ label: 'Farmer Email', type: 'text', id: 'owner_email', label_fr: 'Email du fermier' },
		{ label: 'Dairy Farm Legal Name', type: 'text', id: 'farm_name', label_fr: 'Nom légal de la ferme laitière' },
		{ label: 'Facilitator Representative', type: 'text', id: 'facilitator_name', label_fr: 'Représentant facilitateur' },
		{ label: 'Facilitator Email', type: 'text', id: 'facilitator_email', label_fr: 'Courriel du facilitateur' },
		{ label: 'Farm Primary Address', type: 'text', id: 'address' , label_fr: 'Adresse principale de la ferme' },
		{ label: 'Country', type: 'text', id: 'country', label_fr: 'Pays' },
		{ label: 'State', type: 'text', id: 'state', label_fr: 'État' },
		{ label: 'City', type: 'text', id: 'city', label_fr: 'Ville' },
		{ label: 'Zip/Postal Code', type: 'text', id: 'zip_code' , label_fr: 'Code postal' },
	].map(field => ({ ...field, value: '', error: false, notRequired: true}));
	const [farmInfo, setFarmInfo] = useState(initialFarmInfo);

	let fixed_defaultAnimalInfo = [
		{
			label: 'Breed',
			label_fr: 'Race',
			type: 'dropdown',
			list: breedList,
			id: 'breed',
			notRequired: false,
			helpText: 'This should be whichever breed or breeds are in your herd. for each additional breed, please use the (+) icon below to add a second group',
			helpText_fr: "Cela devrait être quelle que soit la ou les races présentes dans votre troupeau. pour chaque race supplémentaire, veuillez utiliser l'icône (+) ci-dessous pour ajouter un deuxième groupe"
		},
		{
			label: 'Average Number of Lactating Cows (Total Lactating)',
			label_fr: 'Nombre de vaches (nobre total de vaches)',
			type: 'number',
			id: 'cow_numbers',
			notRequired: false,
			helpText: 'The number of total lactating cows on your farm as demonstrated by Dairy Comp or equivalent record',
			helpText_fr: 'Le nombre total de vaches en lactation sur votre ferme tel que démontré par Dairy Comp ou un enregistrement équivalent'
		},
		{
			label: 'Average Number of Lactating Cows on Agolin™',
			label_fr: 'Nombre moyen de vaches en lactation sur Agolin™',
			type: 'number',
			id: 'cow_numbers_on_agolin',
			notRequired: false,
			helpText: 'Average number of lactating cows during the project calendar year (previous calendar year) as demonstrated by Dairy Comp or equivalent record; This number should represent the number during the previous calendar year while using Agolin™. This number needs to be an exact record of average animals. Please upload the document where this value is found below',
			helpText_fr: "Nombre moyen de vaches en lactation pendant l\'année du calendrier du projet (année civile précédente) tel que démontré par Dairy Comp ou un enregistrement équivalent; Ce nombre doit représenter le nombre pendant l\'année civile précédente tout en utilisant Agolin™. Ce nombre doit être un enregistrement exact des animaux moyens. Veuillez télécharger",
		},
		{
			label: 'Average Number of Non-Lactating Animals on Agolin™',
			label_fr: 'Nombre de vaches Taries sur Agolin™',
			type: 'number',
			id: 'cow_numbers_on_agolin_non_lactating',
			notRequired: false,
			helpText: 'This number should represent the number during the last calendar year while using Agolin™. This will represent close-ups, young stock, or dry animals being fed Agolin™',
			helpText_fr: 'Ce nombre doit représenter le nombre pendant l\'année civile précédente tout en utilisant Agolin™. Cela représentera les animaux proches, les jeunes animaux ou les animaux secs nourris avec Agolin™'
		},
		{
			label: 'Agolin™ Start',
			label_fr: 'Début d\'Agolin™',
			type: 'date',
			id: 'agolin_start',
			notRequired: false,
			min: '',
			helpText: 'Start date of feeding Agolin™ in the project calendar year, it will default to the beginning of the project year if you have been feeding before 2024. If you have had time during the year where you have come off and back on the Agolin™ product, please use the (+) icon below to represent the different feeding periods',
			helpText_fr: 'Date de début de l\'alimentation en Agolin™ dans l\'année civile du projet, elle sera par défaut au début de l\'année du projet si vous avez été nourri avant 2024. Si vous avez eu le temps pendant l\'année où vous êtes sorti et revenu sur le produit Agolin™, veuillez utiliser l\'icône (+) ci-dessous pour représenter les différents périodes d\'alimentation'
		},
		{
			label: 'Agolin™ End',
			label_fr: 'Fin d\'Agolin™',
			type: 'date',
			id: 'agolin_end',
			notRequired: true,
			max: '',
			helpText: 'Do not enter a date here unless you stopped using Agolin™ during the previous calendar year or will stop using Agolin™',
			helpText_fr: 'N\'entrez pas de date ici à moins que vous n\'ayez cessé d\'utiliser Agolin™ pendant l\'année civile précédente ou que vous cessiez d\'utiliser Agolin™'
		},
		{
			label: 'Percent Fat in Diet',
			label_fr: 'Pourcentage de matières grasses de la ration',
			type: 'dropdown',
			id: 'percent_fat_in_diet',
			list: percentFat,
			notRequired: false,
			helpText: 'A standard lactation diet typically contains about 2 to 3% fat within the raw materials in the diet. Nutritionists then add more fat to provide additional energy. The combination is the total fat. This data point should represent the total fat in the diet, which is expected to be between 4-6%. Please upload the document where this value is found below',
			helpText_fr: 'Un régime de lactation standard contient généralement environ 2 à 3% de matières grasses dans les matières premières du régime. Les nutritionnistes ajoutent ensuite plus de matières grasses pour fournir de l\'énergie supplémentaire. La combinaison est la graisse totale. Ce point de données doit représenter la graisse totale dans le régime, qui devrait être comprise entre 4 et 6%. Veuillez télécharger le document où cette valeur est trouvée ci-dessous'
		},
		{
			label: 'Agolin™ Fed (Grams per Cow per Day)',
			label_fr: 'Agolin™ Aliment (grammes par vache par jour)',
			type: 'dropdown',
			list: grams_per_list,
			id: 'agolin_grams_per_day',
			notRequired: false,
			helpText: '1 gram of Agolin™ per cow per day is required to participate in the project. If feeding less, please inform your project facilitator or Agolin™ distributor',
			helpText_fr: '1 gramme d\'Agolin™ par vache par jour est requis pour participer au projet. Si vous nourrissez moins, veuillez en informer votre facilitateur de projet ou votre distributeur Agolin™'
		},
		{
			label: 'Average Dry Matter Intake per Day (lbs)',
			label_fr: 'Moyenne annuelle de la consommation volontaire de matière sèche (kg)',
			type: 'text',
			id: 'dry_matter_per_day',
			notRequired: false,
			helpText: 'This number is your average dry matter intake averaged over each diet formulation period during the last calendar year while using Agolin™. Please upload the document where this value is found below',
			helpText_fr: 'Ce nombre est votre consommation moyenne de matière sèche moyennée sur chaque période de formulation de régime pendant l\'année civile précédente tout en utilisant Agolin™. Veuillez télécharger'
		},
		{
			label: 'Milk Sold To',
			label_fr: 'Lait vendu à',
			type: 'dropdown-tag',
			list: [],
			id: 'milk_sold_to',
			notRequired: false,
			helpText: '',
			helpText_fr: '',
		},
		{
			label: 'Type of Agolin™ Product',
			label_fr: 'Type de produit Agolin™',
			type: 'dropdown',
			list: [],
			id: 'agolin_product_used',
			notRequired: false,
			helpText: 'If you are not sure, please contact your Agolin™ distributor',
			helpText_fr: 'Si vous n\'êtes pas sûr, veuillez contacter votre distributeur Agolin™'
		},
		{
			label: 'Organic or Conventional',
			label_fr: 'Biologique ou Conventionnel',
			type: 'dropdown',
			list: organic_or_conventional_list,
			id: 'organic_or_conventional',
			notRequired: false,
		},
	].map(field => ({ ...field, value: '', error: false }));
	const [defaultAnimalInfo, setDefaultAnimalInfo] = useState(fixed_defaultAnimalInfo);
	const [animalInfo, setAnimalInfo] = useState([]);

	const initialFarmPartnerInfo = [
		{ label: 'Nutritionist', type: 'text', id: 'nutritionist', label_fr: 'Nutritionniste' },
		{ label: 'Nutritionist Contact', type: 'phone', id: 'nutritionist_contact', label_fr: 'Contact du nutritionniste' },
		{ label: 'Feed Mill Contact', type: 'phone', id: 'feed_mill_contact', label_fr: 'Contact du moulin à aliments' },
		{ label: 'Feed Mill', type: 'text', id: 'feed_mill', label_fr: 'Moulin à aliments' },
	].map(field => ({ ...field, value: '', error: false, notRequired: true }));
	const [farmPartnerInfo, setFarmPartnerInfo] = useState(initialFarmPartnerInfo);

	const initialReceiptInfo = [
		{
			label: 'Date of First Agolin™ Purchase',
			label_fr: 'Date du premier achat d\'Agolin™',
			type: 'date',
			id: 'date_of_agolin_purchase',
			notRequired: !isFacilitator,
			max: projectInfo.project_end_date,
			helpText: 'The date of first Agolin™ delivery to your farm for the previous calendar year. This should be noted on the mill reciept. This date does not affect the validations for the quantity.',
			helpText_fr: 'La date de la première livraison d\'Agolin™ à votre ferme pour l\'année civile précédente. Cela doit être noté sur le reçu du moulin. Cette date n\'affecte pas les validations pour la quantité.'
		},
		{		
			label: 'Quantity of Agolin™ Purchased (lbs)',
			label_fr: 'Quantité d\'Agolin™ achetée (kg)',
			type: 'text',
			id: 'quantity_of_agolin_purchased',
			notRequired: !isFacilitator,
			helpText: 'Please enter the quantity of Agolin™ Purchased per the uploaded receipts.',
			helpText_fr: 'Veuillez entrer la quantité d\'Agolin™ achetée selon les reçus téléchargés.'
		},
	].map(field => ({ ...field, value: '', error: false }));
	const [receiptInfo, setReceiptInfo] = useState(initialReceiptInfo);


	const getFormData = async (id) => {
		getDataFromUniqueLink(id) // Making the API call here
			.then((getFormDataResponse) => {
				if (getFormDataResponse.status === 200) {
					let new_projectInfo = getFormDataResponse?.data?.result?.project_information;
					setProjectInfo(new_projectInfo);
					setFarmFormStatus(getFormDataResponse?.data?.result?.farm_form_status);
					setFirstTimeLoad(false);
					

					// FARM INFORMATION
					const farm_info_response = getFormDataResponse?.data?.result?.farm_information;
					let new_lang = farm_info_response.state === 'Quebec' ? 'fr' : 'en';
					setLang(new_lang);

					const updatedfarmInfo = farmInfo.map((detail) => {
						// Mapping this farm_info_response to the farmInfo state
						const { id } = detail;
							const value = farm_info_response[id] ?? '';
							return id === 'owner_phone'
								? { ...detail, value:value, state: value === 'Canada' ? 'CA' : 'US' }
								: { ...detail, value:value };
					});					
					setFarmInfo(updatedfarmInfo);


					// ANIMAL INFORMATION
					// Updating Default Animal Info for the projct end date
					fixed_defaultAnimalInfo = fixed_defaultAnimalInfo.map((item) => {
						if (item.id === 'agolin_start') {
							return { ...item, min: new_projectInfo.project_start_date, max: new_projectInfo.project_end_date, value: new_projectInfo.project_start_date };
						} else if (item.id === 'agolin_end') {
							return { ...item, min: new_projectInfo.project_start_date, max: new_projectInfo.project_end_date };
						} else if ((item.id === 'dry_matter_per_day')) {
							return { ...item, label: new_projectInfo.project_country === 'Canada' ? item.label.replace('lbs', 'kg') : item.label };
						} else if ((item.id === 'agolin_product_used')) {
							return { ...item, list: get_agolin_product_used_list(new_projectInfo.project_country) };

						} else if ((item.id === 'milk_sold_to')) {
							let label = new_projectInfo.project_country === 'Canada' ? 'What Cooperative are you part of?' : item.label;
							label = new_lang === 'fr' ? "À quelle coopérative êtes-vous affilié?" : label;
							return { 
								...item, 
								label: label,
								list: get_milk_sold_to_list(new_projectInfo.project_country), 
								helpText: new_projectInfo.project_country === 'Canada' ? '' : 'If your milk is sold to more than one group, please use the (+) icon below to add a new group of animals that represents the volume of milk sold to the second milk buyer/processor' 
							};
						} else {
							return item;
						}
					})
					setDefaultAnimalInfo(fixed_defaultAnimalInfo);

					// AnimalInfo is updated by the use effect hook.
					if (getFormDataResponse?.data?.result?.animal_information?.length > 0) {			
						const data = getFormDataResponse.data.result.animal_information.map((item1) => {
							return (fixed_defaultAnimalInfo).map((property) => ({
								...property,
								value: item1[property.id] ?? property.value,
							}));
						});
						applyDMIValidationOnLoad(data); // Includes saving it to the state
					} else {
						setAnimalInfo([fixed_defaultAnimalInfo]);
					}

					// FARM PARTNER INFORMATION
					const farm_partner_info = getFormDataResponse?.data?.result?.farm_partner_information;
					const updatedFarmPartner = farmPartnerInfo.map((detail) => ({
						...detail,
						value: farm_partner_info[detail.id] ?? '',
					}));
					setFarmPartnerInfo(updatedFarmPartner);

					// RECEIPT INFORMATION
					const receipt_info = getFormDataResponse?.data?.result?.receipt_information;
					// Updating the info received from API call
					let updatedReceiptInfo = receiptInfo.map((detail) => ({
						...detail,
						value: receipt_info[detail.id] ?? '',
					}));
					// Updating the settings
					updatedReceiptInfo = updatedReceiptInfo.map((item) => {
						if (item.id === 'date_of_agolin_purchase') {
							return { ...item, min: new_projectInfo.project_start_date, max: new_projectInfo.project_end_date};
						} else if ((item.id === 'quantity_of_agolin_purchased') && (new_projectInfo.project_country === 'Canada')) {
							return { ...item, label: item.label.replace('lbs', 'kg') };
						} else {
							return item;
						}
					})
					setReceiptInfo(updatedReceiptInfo);

					// DOCUMENT LIST
					setDocusignContract([
						{
							name: getFormDataResponse?.data?.result?.docusign_agreement.document_name,
							file: getFormDataResponse?.data?.result?.docusign_agreement.document_link,
						},
					]);
				}
			})
			.catch((error) => {
				console.log('------------------------------')
				console.log(error);
				console.log('------------------------------')
				toastFailure(error.response.data.message);
			});
	};

	useEffect(() => {
		if (id && isAuthenticated) {
			getDocumentListFun(id);
			getFormData(id);
		}
	}, [isAuthenticated]);

	useEffect(() => {
		setFirstTimeLoad(true);
	}, [firstTimeLoad]);

	// -----------------------------------------------------
	const AddNewAnimalInfo = () => {
		const updateAnimalInfo = Array.from(animalInfo);
		updateAnimalInfo.push(defaultAnimalInfo);
		setAnimalInfo(updateAnimalInfo);
	};

	const RemoveAnimalInfo = (id) => {
		const updateAnimalInfo = Array.from(animalInfo);
		updateAnimalInfo.splice(id, 1);
		setAnimalInfo(updateAnimalInfo);
	};

	const applyDMIValidationOnLoad = (localAnimalInfo) => {
		// This function check s DMI validation for the first time the page loads. This is for facilitators reviewing the data submitted by farmers.
		const fieldIndex = 8;
		const tmpFormArray = Array.from(localAnimalInfo);

		for (let formIndex = 0; formIndex < tmpFormArray.length; formIndex++) {
			const tmpFieldArray = JSON?.parse(
				JSON?.stringify(tmpFormArray[formIndex])
			);
			if (tmpFieldArray[8].value) {
				const value = checkDMIValidation(tmpFieldArray[0].value, parseInt(tmpFieldArray[fieldIndex].value), localStorage.getItem('selectedProjectCountry'));
				value.error
				? (tmpFieldArray[fieldIndex]['message'] = value.message)
				: delete tmpFieldArray[fieldIndex]['message'];
				tmpFormArray[formIndex] = [...tmpFieldArray];
				setFirstTimeLoad(false);
			}
		}
		setAnimalInfo(tmpFormArray);
	};
	const handleChangeAnimal = (fieldName, event, fieldType, formIndex, fieldIndex) => {
		setAnimalInfo(prevAnimalInfo => {
			const updatedAnimalInfo = [...prevAnimalInfo];
			const updatedFieldArray = [...updatedAnimalInfo[formIndex]];

			if (fieldName.includes('Average Dry Matter Intake per Day')|| fieldName.includes('Moyenne annuelle de la consommation volontaire de matière sèche')) {
				updatedFieldArray[fieldIndex].value = event.target.value;
				const value = checkDMIValidation(updatedFieldArray[0].value, parseInt(updatedFieldArray[fieldIndex].value), localStorage.getItem('selectedProjectCountry'));
				if (value.error) {
					updatedFieldArray[fieldIndex].message = value.message;
				} else {
					delete updatedFieldArray[fieldIndex].message;
				}
			} else if (fieldName === 'Milk Sold To' || fieldName === 'Lait vendu à') {
				updatedFieldArray[fieldIndex].value = event;
				updatedFieldArray[fieldIndex].error = false;
			} else {
				updatedFieldArray[fieldIndex].value = event.target.value;
				updatedFieldArray[fieldIndex].error = false;
			}

			updatedAnimalInfo[formIndex] = updatedFieldArray;
			return updatedAnimalInfo;
		});
	};

	const handleSelectAnimalInformationField = (fieldType, value, formIndex, fieldIndex) => {
		setAnimalInfo(prevAnimalInfo => {
			const updatedAnimalInfo = [...prevAnimalInfo];
			const updatedFieldArray = [...updatedAnimalInfo[formIndex]];

			if (fieldType === 'dropdown') {
				updatedFieldArray[fieldIndex] = {
					...updatedFieldArray[fieldIndex],
					value,
					error: false,
				};
			}

			updatedAnimalInfo[formIndex] = updatedFieldArray;
			return updatedAnimalInfo;
		});
		let temp_error = runReceiptInfoValidation();

	};

	const handleChangeFarmPartner = (name, e, type) => {
		const updatedFields = farmPartnerInfo.map((field) => {
			if ((field.label === name) || (field.label_fr === name)) {
				const value = type === 'phone' ? e : e.target.value;
				return { ...field, value, error: false };
			}
			return field;
		});
		setFarmPartnerInfo(updatedFields);
	};

	// -----------------------------------------------------
	const validateQuantityOfAgolinPurchased = (field) => {
		let expected_value = 0;
		for (let i = 0; i < animalInfo.length; i++) {
			const data = animalInfo[i];
			let lactating_cows = Object.values(data).find(item => item.id === "cow_numbers_on_agolin")?.value;
			let feed_per_day = parseFloat(Object.values(data).find(item => item.id === "agolin_grams_per_day")?.value) / 1000; // gm to kg
			let agolin_start_str =Object.values(data).find(item => item.id === "agolin_start")?.value;
			if (agolin_start_str === "") {
				agolin_start_str = Object.values(data).find(item => item.id === "agolin_start")?.min;
			}
			let agolin_end_str = Object.values(data).find(item => item.id === "agolin_end")?.value;
			if (agolin_end_str === "") {
				agolin_end_str = Object.values(data).find(item => item.id === "agolin_end")?.max;
			}
			let feeding_duration = 1+ (new Date(agolin_end_str) - new Date(agolin_start_str)) / (1000 * 60 * 60 * 24); // in days
			expected_value += kg_to_lb(lactating_cows * feed_per_day * feeding_duration); // in lb
		}
		let entered_value = parseFloat(field.value);
		if (projectInfo.project_country === 'Canada') {
			entered_value = kg_to_lb(entered_value);
		}
		let delta = ((entered_value - expected_value)/expected_value)*100;
		console.log(entered_value, expected_value, delta)
		if (delta > 5) {
			let message = lang === 'en' ? 'The quantity of Agolin™ purchased is more than 5% from the expected value per the data above.' : 'La quantité d\'Agolin™ achetée est supérieure de plus de 5% à la valeur attendue selon les données ci-dessus.';
			return { ...field, isError: false, message: message};
		} else if (delta < -5) {
			let message = lang === 'en' ? 'The quantity of Agolin™ purchased is less than 5% from the expected value per the data above.' : 'La quantité d\'Agolin™ achetée est inférieure de plus de 5% à la valeur attendue selon les données ci-dessus.';
			return { ...field, isError: true, message: message};
		} 
		return { ...field, isError: false, message: ''};
	}

	const handleChangeReceiptInfo = (name, e, type) => {
		// Updating with new fields
		let updatedFields = receiptInfo.map((field) => {
			let newField = field
			if ((field.label === name) || (field.label_fr === name)) {
				const value = e.target.value;
				newField = { ...field, value, error: false };
			}
			return newField;
		});

		// Applying Validation
		updatedFields = applyValidationOnReceiptInfo(updatedFields);
		setReceiptInfo(updatedFields);
	};

	const applyValidationOnReceiptInfo = (currentReceiptInfo) => {
		return(currentReceiptInfo.map((field) => {
			let newField = field
			// Applying Validation 
			// Plausibility check for Agolin™ Purchase
			if (field.id === 'quantity_of_agolin_purchased') {
				newField = validateQuantityOfAgolinPurchased(newField);
			}
			return newField;
		}))
	}
	
	// -----------------------------------------------------	
	// Any changes in animalInfo will trigger this useEffect to run validation.
	useEffect(() => {
		setReceiptInfo(applyValidationOnReceiptInfo(receiptInfo))
	}, [animalInfo]);

	// -----------------------------------------------------
  	// -----------------------------------------------------
  	// FILE FUNCTIONS
	const [docusignContract, setDocusignContract] = useState([]);
	const [currDelete, setCurrDelete] = useState(null);  
	const [isUplaodFileLoading, setIsUploadFileLoading] = useState(false);
	const [isDeletingFileLoading, setIsDeletingFileLoading] = useState(false);

	const getDocumentListFun = async (id) => {
		getDocumentList(id)
			.then(async (getDocumentResponse) => {
				if (getDocumentResponse.status === 200) {
					setUploadedFiles(getDocumentResponse.data.result);
					return true;
				}
			})
			.catch((error) => {
				return false;
			});
	};

	const handleDownload = async (fileName) => {
	try {
		const url = await getPresignedURL(fileName);
		const ele = document.createElement('a');
		ele.href = url.data.result;
		ele.setAttribute('download', fileName);
		document.body.appendChild(ele);
		ele.click();
		document.body.removeChild(ele);
	} catch (error) {}
	};

	const uploadFileFunc = async (data) => {
		try {
			const responseFileUpload = await uploadDocument(data);
			if (responseFileUpload.status === 200 || responseFileUpload.status === 201) {
				const response = await getDocumentListFun(id);
				if (response) {
					toastSuccess(responseFileUpload.data.message);
				}
			}
		} catch (error) {
			toastFailure('File upload failed');
		} finally {
			setIsUploadFileLoading(false);
		}
	};

	const fileInputRef = useRef();

	const handleUploadClick = () => {
		fileInputRef.current.click();
	};

	const handleFileUpload = (e) => {
		const files = e.target.files;
		if (files.length > 0) {
			const formData = new FormData();
			formData.append('document', files[0]);
			formData.append('unique_key', id);
			setIsUploadFileLoading(true);
			uploadFileFunc(formData);
		}
	};

	const handleDrop = (event) => {
		event.preventDefault();
		const files = event.dataTransfer.files;
		if (files.length > 0 && farmFormStatus !== 'Approved') {
			const formData = new FormData();
			formData.append('document', files[0]);
			formData.append('unique_key', id);
			setIsUploadFileLoading(true);
			uploadFileFunc(formData);
		}
	};

	const handleDragEnter = (event) => {
		event.preventDefault();
	};
	
	const handleDragOver = (event) => {
		event.preventDefault();
	};
		
	const handleDeleteFile = async (fileId, object) => {
		setIsDeletingFileLoading(true);
		const formData = new FormData();
		formData.append('id', fileId);
		formData.append('s3_object', object);
		const deleteFileResponse = await deleteDocument(formData);
		const response = getDocumentListFun(id);
		if (response) {
		toastSuccess(deleteFileResponse.data.message);
		setIsDeletingFileLoading(false);
		setCurrDelete(null);
		}
	};

	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	const runReceiptInfoValidation = () => {
		let hasError = false;
		const updatedReceiptData = receiptInfo.map((field) => {
			let new_val = { ...field, error: false }

			let check_for_completeness_passed = !(!field.notRequired && (!field.value || (typeof field.value === 'string' && !field.value.trim())))
			if (!check_for_completeness_passed) {
				hasError = true;
				new_val = { ...field, error: true };
			} else {
				// Check for value validation
				if (field.id === 'quantity_of_agolin_purchased') {
					new_val = validateQuantityOfAgolinPurchased(field)
					if (new_val.isError) {
						hasError = true;
					}
				}
			}
			return new_val;
		});
		setReceiptInfo(updatedReceiptData);
		return hasError;
	}

	const handleSave = async (type) => { //type => save, submit, approve
		setIsSaveLoading(true)
		let continueSaving = true;

		// If Submit, running the validations
		if (type === 'submit' || type === 'approve') {
			// HAS ERROR = FALSE by default
			// Validating Files
			let hasError = false;
			let error_message='';
			if (type === 'approve') {
				hasError = uploadedFiles.length === 0; // Requiring atleast one file
				error_message = 'Please upload the Agolin Purchase Receipts and Primary Evidence Documentation.'
			}

			// Validating Farm Animal Information
			const updatedAnimalData = animalInfo.map((entry) =>
				entry.map((field) => {
					let new_val = { ...field, error: false }
					// Check for it being a required field and filled out
					if (!field.notRequired && ((typeof field.value === 'number' && field.value < 0) || (typeof field.value === 'string' && !field.value.trim()))) {
						hasError = true;
						new_val = { ...field, error: true };
						error_message = 'Please complete all the required fields.'
					}
					if (field.id === 'cow_numbers' && parseFloat(field.value) < 0) {
						hasError = true;
						new_val = { ...field, error: true, message: 'Please enter a valid number.'};
						error_message = 'Please enter a valid number for total cows.'
					}
					if (field.id === 'cow_numbers_on_agolin' && parseFloat(field.value) < 1) {
						hasError = true;
						new_val = { ...field, error: true, message: 'Please enter a valid number.'};
						error_message = 'Please enter a valid number for cows on agolin.'
					}
					// Check for date validation
					if (field.id === 'agolin_start' || (field.id === 'agolin_end' && field.value !== '')) {
						if (!(new Date(field.min) <= new Date(field.value) && new Date(field.value) <= new Date(field.max))) {
							new_val = { ...field, error: true, message: 'Please enter a valid date. Start date should be before the end date.' };
							error_message = 'Please enter a valid date. Start date should be before the end date.'
						}
					}
					return new_val;
				})
			);
			setAnimalInfo(updatedAnimalData);
	
			// Validating Receipt Information
			if (isFacilitator) {
				let error_in_receipt_validation = runReceiptInfoValidation()
				if (error_in_receipt_validation) {
					error_message = 'Error in the validation checks.'
					hasError = true;
				}
			}
			console.log(hasError, error_message)
			if (hasError) {
				toastFailure(error_message);
				continueSaving = false;
			}
		}

		if (continueSaving) {
			// Forming the data
			const farm_partner_information = farmPartnerInfo.reduce((acc, field) => {
				acc[field.id] = field.value || null;
				return acc;
			}, {});

			const animal_information = animalInfo.map((entry) => {
				return entry.reduce((acc, { id, value }) => {
					if (id === 'milk_sold_to') {
						acc[id] = value?.map((milkItem) => milkItem) || null;
					} else {
						acc[id] = ['breed', 'agolin_start', 'agolin_end', 'percent_fat_in_diet', 'agolin_grams_per_day', 'agolin_product_used', 'organic_or_conventional'].includes(id) ? value || null : parseFloat(value) || 0;
					}
					return acc;
				}, {});
			});

			const final_receipt_information = receiptInfo.reduce((acc, field) => {
				acc[field.id] = field.id === 'quantity_of_agolin_purchased' ? parseFloat(field.value) || null : field.value || null;
				return acc;
			}, {});

			const formData = {
				unique_key: id,
				flag: type,
				farm_partner_information,
				receipt_information: final_receipt_information,
				animal_information,
			};

			// Sending to database
			try {
				const response = await updateFarmForm(formData);
				if (response.status === 200 || response.status === 201) {
					toastSuccess(response.data.message);
					setFarmFormStatus(response.data.form_status)
				} else {
					toastFailure(response.data);
				}
			} catch (error) {
				toastFailure('An error occurred while submitting the form.');
			}

			// If Approve, marking the approval.
			if (type === 'approve') {
				const response2 = await approveStatus({ unique_key: id });
				if (response2.status === 200) {
					setFarmFormStatus(response2.data.form_status);
					toastSuccess(response2.data.message);
				} else {
					toastFailure('An error occurred while approving the form.');
					toastFailure(response2.data);
				}
			}
		}
		setIsSaveLoading(false);
	};
	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<>
					{isAuthenticated && userPermission ? (
						<div className="form-container">
							<div className="form-content">
							<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
								<img src={LogoNew} alt="Logo" style={{ height: "50px" }} />
								<Button
									text="FAQs"
									width="200px"
									style={{ backgroundColor: "var(--color-primary)" }}
									handleClick={() => window.open(`${window.location.origin}/faqs/farmers`, '_blank')}
								/>
							</div>
								{!projectInfo.form_active_save && (
									<p className="form-subHeading" style={{ color: 'red' }}>
										{lang === 'en' ? 'Please note that the window for saving this form is complete. Please contact: support@carboncredify.ai for any questions' : 'Veuillez noter que la fenêtre pour enregistrer ce formulaire est terminée. Veuillez contacter: support@carboncredify.ai pour toute question.'}
									</p>
								)}
								{projectInfo.form_active_save && !projectInfo.form_active_submit && (
									<p className="form-subHeading" style={{ color: 'red' }}>
										{lang === 'en' ? 
											`Please note that the window for submitting this farm begins on ${projectInfo.farm_form_submission_start}. Until then, you can enter the information and save it, but not submit it for approval. Please contact: support@carboncredify.ai for any questions.` 
											: 
											`Veuillez noter que la fenêtre pour soumettre cette ferme commence le ${projectInfo.farm_form_submission_start}. Jusque-là, vous pouvez saisir les informations et les enregistrer, mais pas les soumettre pour approbation. Veuillez contacter: support@carboncredify.ai pour toute question.`}
									</p>
								)}
								<FormComponent 
									heading="Farm Information"
									heading_fr="Informations sur la ferme" 
									subHeading = "If you find any information in this section incorrect or want updated, please contact us at support@carboncredify.ai"
									subHeading_fr = "Si vous trouvez des informations incorrectes dans cette section ou si vous souhaitez les mettre à jour, veuillez nous contacter à"
									list={farmInfo}
									lang={lang} 
									disable={true} 
								/>
								<div>
									<p className="form-subHeading">{lang === 'en' ? 'Animal Information' : 'Informations sur les animaux'}</p>
									{animalInfo.map((item, index1) => (
										<React.Fragment key={index1 + 1}>
											<AnimalFormComponent
												list={item}
												handleChange={handleChangeAnimal}
												AddNewAnimalInfo={AddNewAnimalInfo}
												index={index1}
												RemoveAnimalInfo={RemoveAnimalInfo}
												formDataLength={animalInfo.length}
												handleSelect={handleSelectAnimalInformationField}
												disable={
													!((farmFormStatus === 'Approved' && userPermission.edit_information_approved) ||
													(farmFormStatus !== 'Approved' && userPermission.edit_information_not_approved))
												}
												lang={lang}
											/>
										</React.Fragment>
									))}
									{farmFormStatus !== 'Approved' && (
										<div className="Add-icon">
											<img src={addIcon} alt="Add" onClick={AddNewAnimalInfo} />
										</div>
									)}
								</div>
								<FormComponent
									heading="Farm Partner Information"
									heading_fr="Informations sur le partenaire agricole"
									list={farmPartnerInfo}
									handleChange={handleChangeFarmPartner}
									lang={lang}
									disable={
										!((farmFormStatus === 'Approved' && userPermission.edit_information_approved) ||
										(farmFormStatus !== 'Approved' && userPermission.edit_information_not_approved))
									}
								/>
								{isFacilitator && (
									<FormComponent
										heading="Receipt Information"
										heading_fr="Informations sur le reçu"
										list={receiptInfo}
										handleChange={handleChangeReceiptInfo}
										lang={lang}
										disable={
											!((farmFormStatus === 'Approved' && userPermission.edit_information_approved) ||
											(farmFormStatus !== 'Approved' && userPermission.edit_information_not_approved))
										}
									/>
								)}
								{((farmFormStatus === 'Approved' && userPermission?.upload_files_approved) ||
								(farmFormStatus !== 'Approved' && userPermission?.upload_files_not_approved)) && (
									<div className="input-form-container" style={{ width: '100%', marginTop: '40px' }}>
										<div className="label" style={{ fontSize: '20px', fontWeight: 600 }}>
											Agolin™ Purchase Receipts and Primary Evidence Documentation<span className="required">*</span>
										</div>
										<div
											onDragEnter={handleDragEnter}
											onDrop={handleDrop}
											onDragOver={handleDragOver}
											onClick={handleUploadClick}
											className="file-container"
										>
											{isUplaodFileLoading ? (
												<Loader height="25px" width="25px" color="#ffffff" radius="1" strokeWidth={5} />
											) : (
												<div className="file-content">
													{lang === 'en' ? (
													<div className="file-text">Upload Document Here</div>
													) : (
													<div className="file-text">Télécharger le document ici</div>
													)}
												</div>
											)}
											<input
												type="file"
												ref={fileInputRef}
												style={{ display: 'none' }}
												multiple={true}
												onChange={handleFileUpload}
												accept="image/png, image/jpeg,application/pdf,application/vnd.ms-excel,application/vnd.ms-outlook"
											/>
										</div>
									</div>
								)}
								{uploadedFiles?.length !== 0 ? (
									<div className="display-list">
										<div className="display-list1">
											<div className="title-text-date">{lang==='en' ? "Document Date" : "Date du document"}</div>
											<div className="title-text">{lang === 'en' ? "Document Name" : "Nom du document"}</div>
										</div>
										{uploadedFiles?.map((files, index2) => (
											<div className="display-list2" key={index2}>
												<div className="inner-text-date">{files.date}</div>
												<div className="inner-text">{files.name}</div>
												<span className="docusign-view" onClick={() => handleDownload(files.s3_object)}>View</span>
												{((farmFormStatus === 'Approved' && userPermission.delete_files_approved) ||
												(farmFormStatus !== 'Approved' && userPermission.delete_files_not_approved)) && (
													<div
														style={{ cursor: 'pointer' }}
														className="delete_icon"
														onClick={() => {
															handleDeleteFile(files.id, files);
															setCurrDelete(index2);
														}}
													>
														{isDeletingFileLoading && currDelete === index2 ? (
															<Loader key={index2} height="25px" width="25px" color="#ffffff" radius="1" strokeWidth={5} />
														) : (
															<img src={DeleteIcon} alt="" />
														)}
													</div>
												)}
											</div>
										))}
									</div>
								) : (lang === 'en' ? (
									<div className="contract-text">No Document Uploaded</div>
									) : (
									<div className="contract-text">Aucun document téléchargé</div>
									)
								)}
								<div className="docuSign-container">
									<div className="label" style={{ fontSize: '20px', fontWeight: 600 }}>
										{lang === 'en' ? 'DocuSign Contract' : 'Contrat DocuSign'}
									</div>
									{docusignContract.length === 0 ? (
										<div className="contract-text">No Contract Available</div>
									) : (
										<div style={{ marginTop: '30px' }}>
											{docusignContract.map((list, index) => (
												<div className="display-list2" key={index}>
													<div className="title-text-docusign">{lang === 'en' ? 'Aggregation Agreement' : 'Accord de Regroupement'}</div>
													<span className="docusign-view" onClick={() => handleDownload(list.name)}>View</span>
												</div>
											))}
										</div>
									)}
								</div>

								< br />
								< br />
								<div className="buttons-end">
									{((farmFormStatus === 'Farmer Pending' || farmFormStatus === 'Pending' || farmFormStatus === 'Rejected')) && (
										<div>
											{/*
											<div>
												<input
													type="checkbox"
													checked={checkboxForSharingData}
													onChange={(e) => setCheckboxForSharingData(e.target.checked)}
													className="handle-check-input"
											/>
												&ensp;“I (Dairy Farmer) hereby give permission for my data to be shared with the milk cooperative as entered in “Milk Sold To” field”
											</div>
											<br />
											*/}
											<div className="buttons-end">
												<Button
													text={lang=== 'en' ? "Save": "Enregistrer"}
													width="200px"
													bgColor="#ffc156"
													handleClick={() => handleSave('save')}
													isLoading={isSaveLoading}
													// disabled={!checkboxForSharingData}
												/>
												{projectInfo.form_active_submit && (
													<Button
														text={lang === 'en' ? "Submit": "Soumettre"}
														width="200px"
														handleClick={() => handleSave('submit')}
														isLoading={isSaveLoading}
														// disabled={!checkboxForSharingData}
														/>
												)}
											</div>
										</div>
									)}
									{isFacilitator && farmFormStatus === 'Facilitator Pending' && (
										<>
											<Button
												text={lang === 'en' ? "Reject": "Rejeter"}
												width="200px"
												bgColor="#788B9A"
												handleClick={() => setIsRejectModalOpen(true)}
												isLoading={isSaveLoading}
											/>
											<Button
												text={lang=== 'en' ? "Save": "Enregistrer"}
												width="200px"
												bgColor="#ffc156"
												handleClick={() => handleSave('save')}
												isLoading={isSaveLoading}
												// disabled={!checkboxForSharingData}
											/>
											<Button
												text={lang === 'en' ? "Save and Approve": "Enregistrer et approuver"}
												width="200px"
												handleClick={() => setIsApproveModalOpen(true)}
												isLoading={isSaveLoading}
											/>
										</>
									)}
									{!isFacilitator && farmFormStatus === 'Facilitator Pending' && (
										<p>{lang === 'en' ? "Pending on Facilitator": "En attente du facilitateur"}</p>
									)}
								</div>
								{farmFormStatus !== 'Approved' && lang === 'en' && (									
									<div className="conditiontext">
										By submitting, you agree to the&nbsp;
										<span className="service-text" onClick={() => navigate('/terms-of-services')}>Terms of Service</span>
										&nbsp; and&nbsp;
										<span className="service-text" onClick={() => navigate('/privacy-policy')}>Privacy Policy</span>
									</div>
								)}
								{farmFormStatus !== 'Approved' && lang === 'fr' && (
									<div className="condition-text">
									En soumettant, vous acceptez les&nbsp;
									<span className="service-text" onClick={() => navigate('/terms-of-services')}>Conditions d'utilisation</span>
									&nbsp; et&nbsp;
									<span className="service-text" onClick={() => navigate('/privacy-policy')}>Politique de confidentialité</span>
									</div>
								)}
								<RejectModal
									isRejectModalOpen={isRejectModalOpen}
									setIsRejectModalOpen={setIsRejectModalOpen}
									setFarmFormStatus={setFarmFormStatus}
									uniqueId={id}
									lang={lang}
								/>
								<ApproveModal
									isApproveModalOpen={isApproveModalOpen}
									setIsApproveModalOpen={setIsApproveModalOpen}
									submitFunction={handleSave}
									runReceiptInfoValidation={runReceiptInfoValidation}
									lang={lang}
								/>

							</div>
						</div>
					) : (
						<Verifyfacilitator
							id={id}
							setIsAuthenticated={setIsAuthenticated}
							lang={lang}
						/>
					)}
				</>
			)}
		</>
	);
};

export default FarmForm;