import React, { useEffect, useState } from 'react';
import OTPInput from 'react-otp-input';
import LogoMrv from '../../images/Auth/SVG 1.svg';
import { Button } from '../../Components/Button/Button';
import { useNavigate } from 'react-router-dom';
import {
  checkPasscode,
  forgetPasscode,
  getOwnerEmail,
} from '../../api/Farm/endpoints';
import { toastFailure, toastSuccess } from '../../Components/Toaster/Toast';

const Verifyfacilitator = ({id, setIsAuthenticated, lang }) => {
	const [otp, setOtp] = useState('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');

  const getOwnberEmailId = async () => {
    getOwnerEmail(id)
      .then((emailResponse) => {
        if (emailResponse.status === 200 || emailResponse.status === 201) {
          setEmail(emailResponse.data.result);
        }
      })
      .catch((error) => {
        toastFailure(error.response.data.message);
      });
  };

  useEffect(() => {
    getOwnberEmailId(id);
  }, []);

  const submitPasscode = async () => {
    if (otp.trim() === '') {
      toastFailure('Please Enter a passcode');
    } else {
      setIsLoading(true);
      const data = {
        unique_key: id,
        passcode: otp,
      };
      checkPasscode(data)
        .then((passcodeResponse) => {
          if (
            passcodeResponse.status === 200 ||
            passcodeResponse.status === 201
          ) {
            toastSuccess(passcodeResponse.data.message);
            setIsAuthenticated(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          toastFailure(error.response.data.message);
          setIsLoading(false);
        });
    }
  };

  const resendNewCode = async () => {
    setOtp('');
    const data = {
      unique_key: id,
    };
    const responseCode = await forgetPasscode(data);
    if (responseCode.status === 200 || responseCode.status === 201) {
      toastSuccess(responseCode.data.message);
    } else {
      toastFailure(responseCode.data);
    }
  };
  return (
    <>
      <div className="forgot-container">
        <div className="container1">
          <div className="logo">
            <img src={LogoMrv} alt="logo" />
          </div>
          <div className="content">
            <p className="login">{lang == 'en' ? "Enter Passcode" : "entrez le code d'accès"}</p>
            <p className="text">
              {lang == 'en' ? "Enter 4 digit passcode to access form with email address" : "Entrez le code d'accès à 4 chiffres pour accéder au formulaire avec l'adresse e-mail"}
              <span className="email-text"> {email}</span>
            </p>
            <div
              className=""
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={<span> </span>}
                inputStyle={{
                  width: '51px',
                  height: '50px',
                  margin: '0px 7px',
                  fontSize: '14px',
                  textAlign: 'center',
                  border: '1px solid #E6E6FC',
                  borderRadius: '6px',
                  outline: 'none',
                  justifyContent: 'center',
                }}
                containerStyle={{ marginTop: '40px' }}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            <div className="button-component">
              <Button
                text="Submit"
                isLoading={isLoading}
                handleClick={() => {
                  submitPasscode();
                }}
              />
            </div>
            <div className="code-text">
              {lang === 'en' ? "Don't have passcode?" : "Vous n'avez pas de code d'accès?"}{' '}
              <span
                className="resend-text"
                style={{
                  color: 'var(--color-primary)',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  resendNewCode();
                }}
              >
                {lang === 'en' ? "Resend a new code" : "Renvoyer un nouveau code"}
              </span>{' '}
            </div>
            <div className="code-text">
              {lang === 'en' ? "Are you a representative?" : "Êtes-vous un représentant?"}{' '}
              <span
                className="resend-text"
                style={{
                  color: 'var(--color-primary)',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate('/login', {
                    state: id,
                  });
                }}
              >
                {lang === 'en' ? "Sign in" : "Se connecter"}
              </span>{' '}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verifyfacilitator;
