import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import './facilator.css';
import SearchIcon from '../../images/Facilitator/SearchImg.svg';
import CheckGray from '../../images/Facilitator/check-gray.svg';
import CheckYellow from '../../images/Facilitator/check-yellow.svg';
import CheckGreen from '../../images/Facilitator/check-green.svg';
import LinkIcon from '../../images/Facilitator/link.svg';
import {
	getFacilitatorPortfolioList,
	sendReminder,
	togglePaidToFarmer,
} from '../../api/facilitator/endpoints';
// import { ButtonWithArrow } from '../../Components/Button/Button';
import Loader from '../../Components/Loader/loader';
import { toastFailure, toastSuccess } from '../../Components/Toaster/Toast';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useDebounce } from '../../Components/hooks/useDebounce';
import ConfirmDeletion from '../../Components/Modals/ConfirmDeletion';
import DeleteIcon from '../../images/Analytics/Group 34070.svg';
import { deleteFarm } from '../../api/Farm/endpoints';

const FacilatorPortfolio = () => {
	const navigate = useNavigate();
	const { userPermission } = useSelector((state) => state.auth);

	const [searchInput, setSearchInput] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingDelete, setIsLoadingDelete] = useState(false);
	const [isLoadingReminder, setIsLoadingReminder] = useState(false);
	const [confirmModal, setConfirmModal] = useState(false);
	const [selectedForDelete, setSelectedForDelete] = useState();
	const [reason, setReason] = useState('');
	const [error, setError] = useState(false);
	const [postAudit, setPostAudit] = useState(false);

	const [facilitatorPortfolioData, setFacilitatorPortfolioData] = useState();

	const { selectedProject } = useSelector((state) => state.project);

	const debouncedHandleSearch = useDebounce(searchInput, 1000);

	const callFacilitatorApi = async (id, search_text = null) => {
		setIsLoading(true);
		const portfolioRes = await getFacilitatorPortfolioList(id, search_text);
		if (portfolioRes.status === 200 || portfolioRes.status === 201) {
			setFacilitatorPortfolioData(portfolioRes.data.result);
			setPostAudit(portfolioRes.data.post_audit);
			setIsLoading(false);
		}
	};

	const togglePaidToFarmerLocal = async (id) => {
		const paidToFarmerRes = await togglePaidToFarmer({'id': id});
		if (paidToFarmerRes.status === 200) {
			toastSuccess(paidToFarmerRes.data.message);
			setFacilitatorPortfolioData((prevData) => {
				return prevData.map((data) => {
					if (data.id === id) {
						return {
							...data,
							paid_out: !data.paid_out,
						};
					}
					return data;
				});
			});
		}
	};

	useEffect(() => {
		if (selectedProject) {
			callFacilitatorApi(selectedProject.id, searchInput);
		}
	}, [debouncedHandleSearch, selectedProject]);

	const sendReminderMail = async (userId) => {
		setIsLoadingReminder(true);
		const data = {
			id: userId,
		};
		const responseReminder = await sendReminder(data);
		if (responseReminder.status === 200 || responseReminder.status === 201) {
			toastSuccess(responseReminder.data.message);
			setIsLoadingReminder(false);
		} else {
			toastSuccess(responseReminder.data);
			setIsLoadingReminder(false);
		}
	};

	const handleDelete = async (data) => {
		if (reason.trim() === '') {
			setError(true);
			return;
		} else {
			setIsLoadingDelete(true);
			const dataObj = {
				unique_key: data.link,
				delete_reason: reason,
			};
			const response = await deleteFarm(dataObj);
			if (response.status === 200 || response.status === 201) {
				setIsLoadingDelete(false);
				toastSuccess(response.data.message);
				callFacilitatorApi(selectedProject.id);
				setConfirmModal(false);
			} else {
				setIsLoadingDelete(false);
				toastFailure(response.data);
				setConfirmModal(false);
			}
		}
	};

	return (
		<>
			<div className="facilitator-container">
				{/* Search Input */}
				<div className="search-box">
					<div className="search-input-field">
						<input
							type="text"
							autocompleted="false"
							value={searchInput}
							// disabled={isLoading}
							onChange={(e) => {
								setSearchInput(e.target.value);
							}}
						/>
						<div className="search-img">
							<img src={SearchIcon} alt="Search-icon" />
						</div>
					</div>
				</div>
				<div className="table-container">
					<div className="table-header">
						<div className="facilator-head">Representative Name</div>
						{/* <div className="project-head">Project Name</div> */}
						<div className="farm-head">Farm Name</div>
						<div className="docusign-head">DocuSign Status</div>
						{!postAudit && (
							<>
								<div className="farmForm-head">Farm Form Status</div>
								<div className="reminder-head">Send a Reminder</div>
								<div className="link-head">Link to Farm Form</div>
							</>
						)}
						{postAudit && (
							<>
								<div className="payout-head">Payout</div>
								<div className="paidout-head">Paid to Farmer?</div>
							</>
						)}
					</div>
					<div className="border-bottom"></div>

					<div className="table-body">
						{isLoading ? (
							<Loader />
						) : (
							<>
								{facilitatorPortfolioData?.length === 0 ||
								typeof facilitatorPortfolioData === 'undefined' ? (
									<>
										<div className="no-data">No data available</div>
									</>
								) : (
									<>
										{facilitatorPortfolioData?.map((list, index1) => {
											return (
												<React.Fragment key={index1 + 1}>
													<div className="table-row">
														<div className="facilator-head">
															{list.facilitator_name}
														</div>
														<div className="farm-head">
															{list.farm_name}
														</div>
														<div className="docusign-head">
																	<div style={{ display: 'inline-flex' }}>
																		{['farmer', 'facilitator', 'aggregator'].map((role, idx) => {
																			const status = list[`docusign_${role}_status`]?.toLowerCase();
																			const icon = status === 'completed' ? CheckGreen : status === 'sent' ? CheckYellow : CheckGray;
																			return (
																				<img key={idx} src={icon} style={{height:'24px', width: '24px'}} alt="check-icon" />
																			);
																		})}
																		{list.docusign_aggregator_status?.toLowerCase() === 'completed' && userPermission?.link_to_aggregation_agreement && (
																			<div
																				data-tooltip-id="my-tooltip"
																				data-tooltip-content="Link to the Signed Aggregation Agreement"
																			>
																				&nbsp;&nbsp;&nbsp;
																				<img
																					src={LinkIcon}
																					style={{ cursor: 'pointer' }}
																					alt="link"
																					onClick={() =>
																						window.open(
																							list.aggregation_agreement_link,
																							'_blank'
																						)
																					}
																				/>
																			</div>
																		)}
																		{list.docusign_status !== 'completed' && (
																			<div
																				className="delete_button"
																				style={{ display: 'inline-flex' }}
																				onClick={() => {
																					setConfirmModal(true);
																					setSelectedForDelete(list);
																				}}
																			>
																				&nbsp;&nbsp;&nbsp;
																				<img
																					src={DeleteIcon}
																					alt=""
																					style={{ width: '100%', height: '100%' }}
																				/>
																			</div>
																		)}
																	</div>
																</div>

														{!postAudit ? (
															<>
																<div className="farmForm-head">
																	{list.farm_form_status === 'Rejected' ? 'Sent' : list.farm_form_status.charAt(0).toUpperCase() + list.farm_form_status.slice(1)}
																</div>
																<div
																	className="reminder-head link-row"
																	style={{
																		cursor: list.docusign_status === 'completed' && userPermission?.send_Reminder ? 'pointer' : 'unset',
																	}}
																	onClick={() => {
																		if (list.docusign_status === 'completed' && userPermission?.send_Reminder && !isLoadingReminder) {
																			sendReminderMail(list.id);
																		}
																	}}
																>
																	{list.docusign_status === 'completed' && list.farm_form_status !== 'Approved' && userPermission?.send_Reminder ? 'Remind' : '-'}
																</div>
																{list.docusign_status === 'completed' && userPermission?.link_to_farm ? (
																	<Link
																		className="link-head link-row"
																		style={{ textDecoration: 'none' }}
																		to={`/farm-form/${list.link}`}
																		target="_blank"
																	>
																		<div
																			className="link-head link-row"
																			style={{
																				textDecoration: 'none',
																				cursor: 'pointer',
																				display: 'flex',
																				width: '100%',
																			}}
																		>
																			Link to farm
																		</div>
																	</Link>
																) : (
																	<div
																		className="link-head link-row"
																		style={{
																			textDecoration: 'none',
																			display: 'flex',
																		}}
																	>
																		-
																	</div>
																)}
															</>
														) : (
															<>
																<div className="payout-head">
																	USD {list.payout.toLocaleString()}
																</div>
																<div className="paidout-head" onClick={() => togglePaidToFarmerLocal(list.id)}>
																	<img
																		src={list.paid_out ? CheckGreen : CheckGray}
																		alt="check-icon"
																	/>
																</div>
															</>
														)}
													</div>
												</React.Fragment>
											);
										})}
									</>
								)}
							</>
						)}
					</div>
				</div>
				<Tooltip id="my-tooltip" className="custom-tooltip" />
				<ConfirmDeletion
					confirmModal={confirmModal}
					setConfirmModal={setConfirmModal}
					handleDelete={handleDelete}
					setSelectedForDelete={setSelectedForDelete}
					selectedForDelete={selectedForDelete}
					isLoading={isLoadingDelete}
					reason={reason}
					setReason={setReason}
					error={error}
					setError={setError}
				/>
			</div>
		</>
	);
};

export default FacilatorPortfolio;
